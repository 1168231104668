import {createApp} from "petite-vue";

import.meta.glob([ '@images/**' ])
import "@styles/app.sass"
import "simple-lightbox/dist/simpleLightbox.css"
import SimpleLightbox from "simple-lightbox";
import { Modal, ModalOpener } from "./partials/modal";
import Swiper from 'swiper';
import {Autoplay, Navigation} from 'swiper/modules';

interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

const headerEl = document.querySelector('[data-header]'),
    headerScope: Header = {
        opened: false,
        handleOpenedHeader(state) {
            document.body.classList.toggle("u-hiddenOverflow", state)
        }
    }

headerEl && createApp(headerScope).mount(headerEl);

(() => {
    if (!document.querySelector('.wp-block-gallery'))
        return

    new SimpleLightbox({
        elements: '.wp-block-gallery figure > a'
    })
})();

(() => {
    if (!document.querySelector('[data-video]'))
        return

    new SimpleLightbox({
        elements: '.HomeHero-video'
    })
})();

(() => { // Count up numbers
    const animationDuration = 2400;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round( animationDuration / frameDuration );
    const easeOutQuad = t => t * ( 2 - t );

    const animateCountUp = (el: HTMLElement) => {
        if (el.classList.contains("is-counted"))
            return;

        el.classList.add("is-counted")
        el.innerHTML = el.innerHTML.replace(/([0-9]+)/g, '<span>$1</span>');
        let frame = 0;

        const number = el.querySelector('span')
        if (number.nextElementSibling?.nodeName === "SPAN") {
            number.innerHTML += number.nextElementSibling.innerHTML
            number.nextElementSibling.remove()
        }

        const countTo = parseInt( number.innerHTML, 10 );
        const counter = setInterval( () => {
            frame++;
            const progress = easeOutQuad( frame / totalFrames ),
                currentCount = Math.round( countTo * progress );

            if ( parseInt( number.innerHTML, 10 ) !== currentCount )
                number.innerHTML = currentCount.toLocaleString()

            frame === totalFrames && clearInterval( counter )
        }, frameDuration );
    };

    function isElementInViewport (el: HTMLElement) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    const runCountUp = () => {
        document.querySelectorAll('[data-about-number]').forEach( (el: HTMLElement) => {
            isElementInViewport(el) && animateCountUp(el);
        });
    }

    runCountUp();
    window.addEventListener("scroll", runCountUp);
})();

(() => {
    let serviceButton: NodeListOf<HTMLButtonElement> = document.querySelectorAll('[data-service-filter]'),
        serviceItems: NodeListOf<HTMLElement> = document.querySelectorAll('[data-service-item]');

    const toggleItems = (type: string) => {
        serviceButton.forEach((el) => {
            el.classList.toggle('is-active', el.id === type);
        });

        serviceItems.forEach((el) => {
            el.classList.remove('u-hidden');
            if (type != 'all' && el.dataset.id != type)
                el.classList.add('u-hidden');
        });

        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.get('type') !== type) {
            queryParams.set("type", type);
            history.pushState(null, null, `?${queryParams.toString()}`);
        }
    }

    serviceButton.forEach((btn) => {
        btn.addEventListener('click', (e) => toggleItems(btn.id ?? 'all'));
    });

    const urlParams = new URLSearchParams(window.location.search ?? '')
    urlParams.has('type') && ['all', 'big', 'small', 'startup'].includes(urlParams.get('type'))
        && toggleItems(urlParams.get('type'))
})();

(() => {
    const slider = document.querySelector('[data-partners-slides]') as HTMLElement
    if (!slider) return

    new Swiper(slider, {
        cssMode: true,
        modules: [Autoplay, Navigation],
        navigation: {
            nextEl: "[data-partners-next]",
            prevEl: "[data-partners-prev]",
        },
        autoplay: {
            delay: 4000,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 16
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 6,
                spaceBetween: 20
            }
        }
    })
})();

document.addEventListener("DOMContentLoaded", () => {
    (() => {
        const slider = document.querySelector('[data-expert-slides]') as HTMLElement
        if (!slider) return

        new Swiper(slider, {
            modules: [Navigation],
            navigation: {
                nextEl: "[data-expert-next]",
                prevEl: "[data-expert-prev]",
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 12
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 16
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 20
                },
            }
        })
    })();

    (() => { // Expert modal
        new Modal("#Modal-expertDetail")
        document.querySelectorAll('[data-modal-opener]')
            .forEach((el: HTMLElement) => new ModalOpener(el))
    })();

    window[window.mapLoaded ? 'initMap' : 'loadMap']()
});