import {log} from "util";

interface openModalDetails {
    id: number
}

interface expertData {
    ID: number
    name: string
    surname: string
    position: string
    content: string
    photo: string
}

declare const expertsData: expertData[]

export class Modal {
    modal: HTMLDialogElement;

    constructor(selector: string) {
        this.modal = document.querySelector(selector)
        if (!this.modal || this.modal.nodeName !== 'DIALOG') return;

        window.addEventListener("open-modal", (ev: CustomEvent<openModalDetails>) => this.openModal(ev.detail.id))

        this.modal.addEventListener("close", () => document.body.classList.remove('u-hiddenOverflow'))
        this.modal.addEventListener("click", (ev) => (ev.target === this.modal) && this.modal.close())

        this.modal.querySelector('[data-modal-closer]')?.addEventListener("click", (ev) => {
            ev.preventDefault()
            this.modal.close()
        })
    }

    openModal(id: number) {
        const data: expertData = expertsData?.find((expert: expertData) => expert.ID === id)
        if (!data) return;

        this.modal.querySelector('[data-name]').innerHTML = data.name + ' ' + data.surname;
        this.modal.querySelector('[data-position]').innerHTML = data.position;
        this.modal.querySelector('[data-quote]').innerHTML = data.content;
        this.modal.querySelector('[data-photo]').innerHTML = data.photo;

        document.body.classList.add('u-hiddenOverflow')
        this.modal.showModal()
    }
}

export class ModalOpener {
    el: HTMLElement;

    constructor(el: HTMLElement) {
        if (!el) return;
        this.el = el

        this.el.addEventListener("click", (e) => {
            if (!e.target.closest("a")) {
                this.openModal()
            }
        })
    }

    openModal() {
        const targetModal = (this.el?.closest('[data-id]') as HTMLElement)?.dataset.id
        targetModal && this.el.dispatchEvent(new CustomEvent<openModalDetails>("open-modal", {
            bubbles: true,
            detail: { id: parseInt(targetModal) }
        }))
    }
}